import {data} from "./data";

let rawTestingUrls = [];
let rawStagingUrls = [];
let rawProductionUrls = [];

let cleanTestingUrls = [];
let cleanStagingUrls = [];
let cleanProductionUrls = [];


//--------------- get the filenamesfor the images, based on the actiual url.-----------------------


for (const element of data) {
	rawTestingUrls.push(element.Environments.testing.url);
	rawStagingUrls.push(element.Environments.staging.url);
	rawProductionUrls.push(element.Environments.production.url);
}

rawTestingUrls.forEach(function (site) {
	cleanTestingUrls.push(site.substr(8).replace(/\//g, '_').replace(/\./g, '-'))
});

rawStagingUrls.forEach(function (site) {
	cleanStagingUrls.push(site.substr(8).replace(/\//g, '_').replace(/\./g, '-'))
});

rawProductionUrls.forEach(function (site) {
	cleanProductionUrls.push(site.substr(8).replace(/\//g, '_').replace(/\./g, '-'))
});

// console.log(sitesNamed);
// add the image key to the data object
data.forEach(function (element) {
	_.set(element, 'Environments.production.img', 999);
	_.set(element, 'Environments.staging.img', 999);
	_.set(element, 'Environments.testing.img', 999);
});

// give the correct string to the image key in the data object
for (let i = 0; i < cleanTestingUrls.length; i++) {
	data[i].Environments.testing.img = cleanTestingUrls[i] + ".png";
}

for (let i = 0; i < cleanStagingUrls.length; i++) {
	data[i].Environments.staging.img = cleanStagingUrls[i] + ".png";
}

for (let i = 0; i < cleanProductionUrls.length; i++) {
	data[i].Environments.production.img = cleanProductionUrls[i] + ".png";
}

// console.log(rawTestingUrls, rawStagingUrls, rawProductionUrls);

//--------------------------------------

//--------------- filter out projects that are not needed -----------------------

var dataFiltered = data.filter(function (el) {

		if (el.Name != 'boilerplate' && el.Name != 'libs' && el.Name != 'default' && el.Name != 'broca' && el.Name != 'broca-error' && el.Name != 'optimize' && el.Name != 'preview-tool') {

			return el.Name 

		}
  });


// console.log(dataFiltered);

//--------------------------------------

var tpl = _.template($("#tpl-list").html());


$(document).ready(function () {
	$.get("https://builder.broqa.de/list").success(function(data) {
		$(".project-list").html(tpl({projects: data}));
		// preparebuttons();
		// preparecollapsibles();
	}).error(function (xhr, txt, err) {
		$(".project-head .project-head-description").html('<span class="glyphicon glyphicon-remove text-danger"></span> Es ist ein Fehler aufgetreten!');
	})

	// $(".project-list").html(tpl({
	// 	projects: dataFiltered
	// }));
	// preparebuttons();
	// preparecollapsibles();

});

// var preparebuttons = function () {
	
// 	$("button.btn").click(function (e) {
// 		var project = $(this).data('project');
// 		var environment = $(this).data('environment');
// 		$(this).addClass('btn-working');
// 		$(this).html('<span class="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span> deploying...');
// 		$('.result-' + project + ' .result-head').show();
// 		$('.result-' + project + ' .result-head samp').text('Deploying ' + project + ' to ' + environment + ' ...');
// 		$.get("/" + project + "/" + environment)
// 			.done(_.bind(function (ret) {
// 				$('.result-' + project + ' .result-head samp').append(' <span class="text-success">done</span>');
// 				$('#collapse-' + project + ' pre').text(ret);
// 				$('#collapse-' + project + '.collapse').collapse('show');
// 				$(this).html('<span class="glyphicon glyphicon-ok"></span> ' + environment)
// 			}, this))
// 			.fail(_.bind(function (xhr, txt, err) {
// 				$('.result-' + project + ' .result-head samp').append(' <span class="text-danger">error</span>');
// 				$('#collapse-' + project + ' pre').text(err + '\n\n' + xhr.responseText);
// 				$('#collapse-' + project + '.collapse').collapse('show');
// 				$(this).html('<span class="glyphicon glyphicon-remove"></span> ' + environment)
// 			}, this));
// 	});
// };

// var preparecollapsibles = function () {
// 	$('.result-collapse').on('hide.bs.collapse', function () {
// 		var cid = $(this).attr('id');
// 		$('a[aria-controls=' + cid + '] span').toggleClass('glyphicon-chevron-up').toggleClass('glyphicon-chevron-down')
// 	});
// 	$('.result-collapse').on('show.bs.collapse', function () {
// 		var cid = $(this).attr('id');
// 		$('a[aria-controls=' + cid + '] span').toggleClass('glyphicon-chevron-up').toggleClass('glyphicon-chevron-down')
// 	});
// 	$('.btn-remove').click(function (e) {
// 		e.preventDefault();
// 		$(this).parent().hide().next().collapse('hide').parents('.container').find('.btn-deploy span.glyphicon').remove();
// 	});
// }