(function ($) {

  $(document).ready(function () {

    // **********************

    // check for ios
    if (/ipad|iphone|ipod/i.test(navigator.userAgent)) {
      $('html').addClass('ios');
    }

    // **********************

    $('.passage a, .paragraph-publication a, a[href*="gaia-group.com"], a[href*="servier.com"], a[href*="google.de"]').not('.btn').attr('target', '_blank');

    // ********** jQuery plugins ************ 
    
    $('body').scrollToAnchors();
    
    $('.glyphicons').glyphClone();

    $('.jumbotron a[title^=button]').mdLinkToButton();
    
    $('.spots a[title^=button]').mdLinkToButton();
    
    $('.stripe a[title^=button]').mdLinkToButton();
    
    $('.jumbotron img').mdImageSize();
    
    $('body').bobButton();

    $(window).scrollTopArrow();

    $('body').fadePage();

    $('body').bobFormAccess();

    $('.bob-frame-show').bobframe();

    // **********************

  });

}(jQuery));
