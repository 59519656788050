export let data = [{
    "Name": "amiria-com",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/6530812",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/6218812",
            "remote": "bob-server:/var/www/amiria.com",
            "skipbuild": false,
            "url": "https://amiria.com"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/6530812",
            "remote": "bob-server:/var/www/amiria-com-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://amiria-com-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/6530812",
            "remote": "bob-server:/var/www/amiria-com-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://amiria-com-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "boilerplate",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/6530812",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "html": {
            "environment": "html",
            "fontcsskey": "6774252/6530812",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/6218812",
            "remote": "bob-server:/var/www/boilerplate.com",
            "skipbuild": false,
            "url": "https://boilerplate.com"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/6530812",
            "remote": "bob-server:/var/www/boilerplate.bob.broqa.de",
            "skipbuild": false,
            "url": "https://boilerplate.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/6530812",
            "remote": "bob-server:/var/www/boilerplate.bob.broqa.de",
            "skipbuild": false,
            "url": "https://boilerplate.bob.broqa.de"
        }
    }
}, {
    "Name": "broca",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/627882",
            "pathscript": "/",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/684702",
            "pathscript": "https://origin.brocaio.com/bob/brocaio.com/",
            "remote": "brocaio.com",
            "skipbuild": false,
            "url": "brocaio.com"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/627882",
            "pathscript": "http://broca.staging.bob.brocaio.com/",
            "remote": "broca.staging",
            "skipbuild": false,
            "url": "broca.staging.bob.brocaio.com"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/627882",
            "pathscript": "http://broca.testing.bob.brocaio.com/",
            "remote": "broca.testing",
            "skipbuild": false,
            "url": "broca.testing.bob.brocaio.com"
        }
    }
}, {
    "Name": "broca-error",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/627882",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/684702",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/627882",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/627882",
            "remote": "broca-err-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "broca-err-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "covivio-de",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/627882",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/684702",
            "remote": "bob-server:/var/www/covivio.de",
            "skipbuild": false,
            "url": "https://covivio.de"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/covivio-de-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://covivio-de-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/covivio-de-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://covivio-de-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "dak-kopfschmerz-coach",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/6530812",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/6218812",
            "remote": "bob-server:/var/www/dak-kopfschmerz-coach.de",
            "skipbuild": false,
            "url": "https://dak-kopfschmerz-coach.de"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/6530812",
            "remote": "bob-server:/var/www/dak-kopfschmerz-coach-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://dak-kopfschmerz-coach-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/6530812",
            "remote": "bob-server:/var/www/dak-kopfschmerz-coach-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://dak-kopfschmerz-coach-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "dak-rueckenfit",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/6530812",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/6218812",
            "remote": "bob-server:/var/www/dak-rueckenfit.de",
            "skipbuild": false,
            "url": "https://dak-rueckenfit.de"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/6530812",
            "remote": "bob-server:/var/www/dak-rueckenfit-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://dak-rueckenfit-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/6530812",
            "remote": "bob-server:/var/www/dak-rueckenfit-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://dak-rueckenfit-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "dak-rueckenfit-bgm",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/6530812",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/6218812",
            "remote": "bob-server:/var/www/dak-rueckenfit-bgm.de",
            "skipbuild": false,
            "url": "https://dak-rueckenfit-bgm.de"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/6530812",
            "remote": "bob-server:/var/www/dak-rueckenfit-bgm-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://dak-rueckenfit-bgm-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/6530812",
            "remote": "bob-server:/var/www/dak-rueckenfit-bgm-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://dak-rueckenfit-bgm-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "dak-smart4me",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/6530812",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "html": {
            "environment": "html",
            "fontcsskey": "6774252/6530812",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/6218812",
            "remote": "bob-server:/var/www/dak-smart4me.de",
            "skipbuild": false,
            "url": "https://dak-smart4me.de"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/6530812",
            "remote": "bob-server:/var/www/dak-smart4me-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://dak-smart4me-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/6530812",
            "remote": "bob-server:/var/www/dak-smart4me-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://dak-smart4me-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "default",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/627882",
            "remote": "",
            "sass": false,
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/684702",
            "remote": "bob-server:/var/www/default.bob.broqa.de",
            "remoteold": "devel@brocaio.com:/mnt/share/srv/static/default",
            "sass": false,
            "skipbuild": false,
            "url": "https://default.bob.broqa.de"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/default-staging.bob.broqa.de",
            "remoteold": "devel@brocaio.com:/mnt/share/srv/static/default.staging",
            "sass": false,
            "skipbuild": false,
            "url": "https://default-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/default-testing.bob.broqa.de",
            "remoteold": "devel@brocaio.com:/mnt/share/srv/static/default.testing",
            "sass": false,
            "skipbuild": false,
            "url": "https://default-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "deprexis-ch",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/627882",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/684702",
            "remote": "bob-server:/var/www/deprexis.ch",
            "skipbuild": false,
            "url": "https://deprexis.ch"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/deprexis-ch-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://deprexis-ch-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/deprexis-ch-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://deprexis-ch-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "deprexis-ch-com",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/6530812",
            "multilingual": true,
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "html": {
            "environment": "html",
            "fontcsskey": "6774252/6530812",
            "multilingual": true,
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/6218812",
            "multilingual": true,
            "remote": "bob-server:/var/www/ch.deprexis.com",
            "skipbuild": false,
            "url": "https://ch.deprexis.com"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/6530812",
            "multilingual": true,
            "remote": "bob-server:/var/www/deprexis-ch-com-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://deprexis-ch-com-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/6530812",
            "multilingual": true,
            "remote": "bob-server:/var/www/deprexis-ch-com-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://deprexis-ch-com-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "deprexis-css",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/6530812",
            "multilingual": true,
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "html": {
            "environment": "html",
            "fontcsskey": "6774252/6530812",
            "multilingual": true,
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/6218812",
            "multilingual": true,
            "remote": "bob-server:/var/www/css.deprexis.ch",
            "skipbuild": false,
            "url": "https://css.deprexis.ch"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/6530812",
            "multilingual": true,
            "remote": "bob-server:/var/www/deprexis-css-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://deprexis-css-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/6530812",
            "multilingual": true,
            "remote": "bob-server:/var/www/deprexis-css-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://deprexis-css-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "deprexis-de",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/627882",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/684702",
            "remote": "bob-server:/var/www/deprexis.de",
            "skipbuild": false,
            "url": "https://deprexis.de"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/deprexis-de-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://deprexis-de-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/deprexis-de-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://deprexis-de-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "deprexis-global",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/6530812",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "html": {
            "environment": "html",
            "fontcsskey": "6774252/6530812",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/6218812",
            "remote": "bob-server:/var/www/deprexis.com",
            "skipbuild": false,
            "url": "https://deprexis.com"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/6530812",
            "remote": "bob-server:/var/www/deprexis-global-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://deprexis-global-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/6530812",
            "remote": "bob-server:/var/www/deprexis-global-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://deprexis-global-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "deprexis-gr",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/6625612",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "html": {
            "environment": "html",
            "fontcsskey": "6774252/6625612",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/6945612",
            "remote": "bob-server:/var/www/gr.deprexis.com",
            "skipbuild": false,
            "url": "https://gr.deprexis.com"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/6625612",
            "remote": "bob-server:/var/www/deprexis-gr-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://deprexis-gr-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/6625612",
            "remote": "bob-server:/var/www/deprexis-gr-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://deprexis-gr-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "deprexis-servier",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/627882",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/684702",
            "remote": "bob-server:/var/www/servier.deprexis.com",
            "skipbuild": false,
            "url": "https://servier.deprexis.com"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/deprexis-servier-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://deprexis-servier-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/deprexis-servier-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://deprexis-servier-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "deprexis-studien",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/627882",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/684702",
            "remote": "bob-server:/var/www/deprexis-studien.de",
            "skipbuild": false,
            "url": "https://deprexis-studien.de"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/deprexis-studien-de-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://deprexis-studien-de-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/deprexis-studien-de-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://deprexis-studien-de-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "deprexis-trials",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/627882",
            "remote": "",
            "skipbuild": true,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/684702",
            "remote": "bob-server:/var/www/deprexis-trials.com",
            "skipbuild": true,
            "url": "https://deprexis-trials.com"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/deprexis-trials-com-staging.bob.broqa.de",
            "skipbuild": true,
            "url": "https://deprexis-trials-com-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/deprexis-trials-com-testing.bob.broqa.de",
            "skipbuild": true,
            "url": "https://deprexis-trials-com-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "deprexis-us",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/6530812",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "html": {
            "environment": "html",
            "fontcsskey": "6774252/6530812",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/6218812",
            "remote": "bob-server:/var/www/us.deprexis.com",
            "skipbuild": false,
            "url": "https://us.deprexis.com"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/6530812",
            "remote": "bob-server:/var/www/deprexis-us-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://deprexis-us-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/6530812",
            "remote": "bob-server:/var/www/deprexis-us-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://deprexis-us-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "drbeck-de",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/627882",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/684702",
            "remote": "bob-server:/var/www/drbeck.pro",
            "skipbuild": false,
            "url": "https://drbeck.pro"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/drbeck-pro-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://drbeck-pro-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/drbeck-pro-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://drbeck-pro-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "drbeck-de-beta",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/627882",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/684702",
            "remote": "bob-server:/var/www/drbeck.pro",
            "skipbuild": false,
            "url": "https://drbeck.pro"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/drbeck-pro-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://drbeck-pro-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/drbeck-pro-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://drbeck-pro-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "gaia-group-com",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/6530812",
            "remote": "",
            "skipbuild": true,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/6218812",
            "remote": "bob-server:/var/www/gaia-group.com",
            "skipbuild": true,
            "url": "https://gaia-group.com"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/6530812",
            "remote": "bob-server:/var/www/gaia-group-com-staging.bob.broqa.de",
            "skipbuild": true,
            "url": "https://gaia-group-com-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/6530812",
            "remote": "bob-server:/var/www/gaia-group-com-testing.bob.broqa.de",
            "skipbuild": true,
            "url": "https://gaia-group-com-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "gaia-group-de",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/6530812",
            "locales": [{
                "href": "/de/",
                "item": "deutsch"
            }, {
                "href": "//gaia-group-en.bob.cartman.local/en/",
                "item": "english"
            }],
            "remote": "./build/de/",
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/6218812",
            "locales": [{
                "href": "//gaia-group.com/de/",
                "item": "deutsch"
            }, {
                "href": "//gaia-group.com/en/",
                "item": "english"
            }],
            "remote": "bob-server:/var/www/gaia-group.com/de",
            "url": "https://gaia-group.com/de/",
            "xxxremote": "devel@brocaio.com:/mnt/share/srv/static/gaia-group-de"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/6530812",
            "locales": [{
                "href": "//gaia-group-com-staging.bob.broqa.de/de/",
                "item": "deutsch"
            }, {
                "href": "//gaia-group-com-staging.bob.broqa.de/en/",
                "item": "english"
            }],
            "remote": "bob-server:/var/www/gaia-group-com-staging.bob.broqa.de/de",
            "url": "https://gaia-group-com-staging.bob.broqa.de/de/"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/6530812",
            "locales": [{
                "href": "//gaia-group-com-testing.bob.broqa.de/de/",
                "item": "deutsch"
            }, {
                "href": "//gaia-group-com-testing.bob.broqa.de/en/",
                "item": "english"
            }],
            "remote": "bob-server:/var/www/gaia-group-com-testing.bob.broqa.de/de",
            "url": "https://gaia-group-com-testing.bob.broqa.de/de/"
        }
    }
}, {
    "Name": "gaia-group-en",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/6530812",
            "lists": [{
                "source": "./content/page3/news",
                "target": "./build/news/",
                "template": "./templates/lists/news_single.tpl"
            }],
            "locales": [{
                "href": "http://gaia-group-de.bob.cartman.local/de/",
                "item": "deutsch"
            }, {
                "href": "/en/",
                "item": "english"
            }],
            "remote": "./build/en/",
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/6218812",
            "lists": [{
                "source": "./content/page3/news",
                "target": "./build/news/",
                "template": "./templates/lists/news_single.tpl"
            }],
            "locales": [{
                "href": "//gaia-group.com/de/",
                "item": "deutsch"
            }, {
                "href": "//gaia-group.com/en/",
                "item": "english"
            }],
            "remote": "bob-server:/var/www/gaia-group.com/en",
            "url": "https://gaia-group.com/en/",
            "xxxremote": "devel@brocaio.com:/mnt/share/srv/static/gaia-group-en"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/6530812",
            "lists": [{
                "source": "./content/page3/news",
                "target": "./build/news/",
                "template": "./templates/lists/news_single.tpl"
            }],
            "locales": [{
                "href": "//gaia-group-com-staging.bob.broqa.de/de/",
                "item": "deutsch"
            }, {
                "href": "//gaia-group-com-staging.bob.broqa.de/en/",
                "item": "english"
            }],
            "remote": "bob-server:/var/www/gaia-group-com-staging.bob.broqa.de/en",
            "url": "https://gaia-group-com-staging.bob.broqa.de/en/"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/6530812",
            "lists": [{
                "source": "./content/page3/news",
                "target": "./build/news/",
                "template": "./templates/lists/news_single.tpl"
            }],
            "locales": [{
                "href": "//gaia-group-com-testing.bob.broqa.de/de/",
                "item": "deutsch"
            }, {
                "href": "//gaia-group-com-testing.bob.broqa.de/en/",
                "item": "english"
            }],
            "remote": "bob-server:/var/www/gaia-group-com-testing.bob.broqa.de/en",
            "url": "https://gaia-group-com-testing.bob.broqa.de/en/"
        }
    }
}, {
    "Name": "ilucia-com",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/6530812",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/6218812",
            "remote": "bob-server:/var/www/ilucia.com",
            "skipbuild": false,
            "url": "https://ilucia.com"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/6530812",
            "remote": "bob-server:/var/www/ilucia-com-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://ilucia-com-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/6530812",
            "remote": "bob-server:/var/www/ilucia-com-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://ilucia-com-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "libs",
    "Environments": {
        "development": {
            "environment": "development",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "remote": "bob-server:/var/www/libs.bob.broqa.de",
            "skipbuild": false,
            "url": "https://libs.bob.broqa.de"
        },
        "staging": {
            "environment": "staging",
            "remote": "bob-server:/var/www/libs-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://libs-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "remote": "bob-server:/var/www/libs-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://libs-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "mailchecker",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/627882",
            "remote": "",
            "sass": false,
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/684702",
            "remote": "bob-server:/var/www/mailchecker.broqa.de",
            "sass": false,
            "skipbuild": false,
            "url": "https://mailchecker.broqa.de"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/mailchecker-staging.bob.broqa.de",
            "sass": false,
            "skipbuild": false,
            "url": "https://mailchecker-staging.bob.brocaio.com"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/mailchecker-testing.bob.broqa.de",
            "sass": false,
            "skipbuild": false,
            "url": "https://mailchecker-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "optimize",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/627882",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/684702",
            "remote": "bob-server:/var/www/optimize.broqa.de",
            "skipbuild": false,
            "url": "https://optimize.broqa.de"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/optimize-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://optimize-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/optimize-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://optimize-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "optimune-studie",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/627882",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/684702",
            "remote": "bob-server:/var/www/optimune-studie.de",
            "skipbuild": false,
            "url": "https://optimune-studie.de"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/optimune-studie-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://optimune-studie-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/optimune-studie-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://optimune-studie-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "plexus-kvn",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/627882",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/684702",
            "remote": "bob-server:/var/www/plexus-kvn.de",
            "skipbuild": false,
            "url": "https://plexus-kvn.de"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/plexus-kvn-de-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://plexus-kvn-de-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/plexus-kvn-de-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://plexus-kvn-de-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "preview-tool",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/6530812",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/6218812",
            "remote": "bob-server:/var/www/preview.com",
            "skipbuild": false,
            "url": "https://preview.com"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/6530812",
            "remote": "bob-server:/var/www/preview-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://preview-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/6530812",
            "remote": "bob-server:/var/www/preview-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://preview-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "priovi-studie",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/627882",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/684702",
            "remote": "bob-server:/var/www/priovi-studie.de",
            "skipbuild": true,
            "url": "https://priovi-studie.de"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/priovi-studie-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://priovi-studie-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/priovi-studie-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://priovi-studie-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "ra-coach-en",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/627882",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/684702",
            "remote": "bob-server:/var/www/ra-coach.com",
            "skipbuild": false,
            "url": "https://ra-coach.com"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/ra-coach-com-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://ra-coach-com-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/ra-coach-com-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://ra-coach-com-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "relido-de",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/6530812",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "html": {
            "environment": "html",
            "fontcsskey": "6774252/6530812",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/6218812",
            "remote": "bob-server:/var/www/relido.de",
            "skipbuild": false,
            "url": "https://relido.de"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/6530812",
            "remote": "bob-server:/var/www/relido-de-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://relido-de-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/6530812",
            "remote": "bob-server:/var/www/relido-de-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://relido-de-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "reviga-ch",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/627882",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/684702",
            "remote": "bob-server:/var/www/reviga.ch",
            "skipbuild": false,
            "url": "https://reviga.ch"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/reviga-ch-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://reviga-ch-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/reviga-ch-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://reviga-ch-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "reviga-de",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/627882",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/684702",
            "remote": "bob-server:/var/www/reviga.de",
            "skipbuild": false,
            "url": "https://reviga.de"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/reviga-de-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://reviga-de-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/reviga-de-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://reviga-de-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "somnovia-ch",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/627882",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/684702",
            "remote": "bob-server:/var/www/somnovia.ch",
            "skipbuild": false,
            "url": "https://somnovia.ch"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/somnovia-ch-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://somnovia-ch-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/somnovia-ch-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://somnovia-ch-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "somnovia-dak",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/627882",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/684702",
            "remote": "bob-server:/var/www/dak.somnovia.de",
            "skipbuild": false,
            "url": "https://dak.somnovia.de"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/somnovia-dak-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://somnovia-dak-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/somnovia-dak-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://somnovia-dak-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "valecura",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/6530812",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/6218812",
            "remote": "bob-server:/var/www/valecura.de",
            "skipbuild": false,
            "url": "https://valecura.de"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/6530812",
            "remote": "bob-server:/var/www/valecura-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://valecura-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/6530812",
            "remote": "bob-server:/var/www/valecura-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://valecura-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "velibra-ch",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/627882",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/684702",
            "remote": "bob-server:/var/www/velibra.ch",
            "skipbuild": false,
            "url": "https://velibra.ch"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/velibra-ch-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://velibra-ch-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/velibra-ch-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://velibra-ch-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "velibra-de",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/627882",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/684702",
            "remote": "bob-server:/var/www/velibra.de",
            "skipbuild": false,
            "url": "https://velibra.de"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/velibra-de-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://velibra-de-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/velibra-de-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://velibra-de-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "veo-team",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/6530812",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "html": {
            "environment": "html",
            "fontcsskey": "6774252/6530812",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/6218812",
            "remote": "bob-server:/var/www/veo-team.de",
            "skipbuild": false,
            "url": "https://veo-team.de"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/6530812",
            "remote": "bob-server:/var/www/veo-team-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://veo-team-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/6530812",
            "remote": "bob-server:/var/www/veo-team-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://veo-team-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "veovita-de",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/627882",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/684702",
            "remote": "bob-server:/var/www/veovita.de",
            "skipbuild": false,
            "url": "https://veovita.de"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/veovita-de-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://veovita-de-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/veovita-de-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://veovita-de-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "veovita-global",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/627882",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/684702",
            "remote": "bob-server:/var/www/veovita-global.com",
            "skipbuild": false,
            "url": "https://veovita-global.com"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/veovita-global-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://veovita-global-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/veovita-global-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://veovita-global-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "veovita-privat",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/627882",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/684702",
            "remote": "bob-server:/var/www/veovita-privat.de",
            "skipbuild": true,
            "url": "https://veovita-privat.de"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/veovita-privat-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://veovita-privat-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/veovita-privat-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://veovita-privat-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "veovita-video",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/627882",
            "remote": "",
            "skipbuild": true,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/684702",
            "remote": "bob-server:/var/www/video.veovita.de",
            "skipbuild": true,
            "url": "https://video.veovita.de"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/veovita-video-staging.bob.broqa.de",
            "skipbuild": true,
            "url": "https://veovita-video-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/627882",
            "remote": "bob-server:/var/www/veovita-video-testing.bob.broqa.de",
            "skipbuild": true,
            "url": "https://veovita-video-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "viamica-de",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/6530812",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/6218812",
            "remote": "bob-server:/var/www/viamica.de",
            "skipbuild": false,
            "url": "https://viamica.de"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/6530812",
            "remote": "bob-server:/var/www/viamica-de-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://viamica-de-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/6530812",
            "remote": "bob-server:/var/www/viamica-de-testing.bob.broqa.de",
            "skipbuild": false,
            "url": "https://viamica-de-testing.bob.broqa.de"
        }
    }
}, {
    "Name": "vorvida-dak",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/6530812",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/6218812",
            "remote": "bob-server:/var/www/vorvida.de/dak",
            "skipbuild": false,
            "url": "https://vorvida.de/dak"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/6530812",
            "remote": "bob-server:/var/www/vorvida-de-staging.bob.broqa.de/dak",
            "skipbuild": false,
            "url": "https://vorvida-de-staging.bob.broqa.de/dak"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/6530812",
            "remote": "bob-server:/var/www/vorvida-de-testing.bob.broqa.de/dak",
            "skipbuild": false,
            "url": "https://vorvida-de-testing.bob.broqa.de/dak"
        }
    }
}, {
    "Name": "vorvida-de",
    "Environments": {
        "development": {
            "environment": "development",
            "fontcsskey": "6774252/6530812",
            "remote": "",
            "skipbuild": false,
            "url": ""
        },
        "production": {
            "environment": "production",
            "fontcsskey": "6774252/6218812",
            "remote": "bob-server:/var/www/vorvida.de",
            "skipbuild": true,
            "url": "https://vorvida.de"
        },
        "staging": {
            "environment": "staging",
            "fontcsskey": "6774252/6530812",
            "remote": "bob-server:/var/www/vorvida-de-staging.bob.broqa.de",
            "skipbuild": false,
            "url": "https://vorvida-de-staging.bob.broqa.de"
        },
        "testing": {
            "environment": "testing",
            "fontcsskey": "6774252/6530812",
            "remote": "bob-server:/var/www/vorvida-de-testing.bob.broqa.de",
            "skipbuild": true,
            "url": "https://vorvida-de-testing.bob.broqa.de"
        }
    }
}];