(function ($) {

  $.fn.bobFormAccess = function () {

    var element = this; // body

    var msg = null;

    var successTimeout = null;

    var bobMsgHide = function (fade=true) {
      // if(!duration) {
      //   duration = 0;
      // }
      let duration = (fade) ? 200 : 0; 
      $('.bob-msg').fadeOut(duration, function () {
        $('.bob-msg .msg-content').hide();
      });
      msg = null;
    };

    var bobMsgSuccess = function () {
      msg = 'success';      
      bobMsgShow(msg);
      successTimeout = window.setTimeout(bobMsgHide, 3000, 200);

      // bobHide();
      var event = $.Event('dohide.bobframe');
      $('.bob-frame').trigger(event);
    };

    var bobMsgWarning = function () {
      msg = 'warning';      
      bobMsgShow(msg);
    };

    var bobMsgError = function () {
      msg = 'error';      
      bobMsgShow(msg);
    };

    var bobMsgShow = function(msg) {
      clearTimeout(successTimeout);
      $('.bob-msg .msg-' + msg).show();
      $('.bob-msg').fadeIn(200);
    }

    var sendData = function (e) {

      e.preventDefault();

      $('#submit', '.bob-frame').prop('disabled', true);
      $('.form-group input', '.bob-frame').removeClass('is-invalid');

      bobMsgHide(false);

      var data = {};
      var label = {};
      $(':input', e.target).each(function() {
        data[this.name] = $(this).val();
        label[this.name] = $("label[for='"+$(this).attr('id')+"']", e.target).text();
      });

      var notValid = false;

      for(var i=4; i>0; i--) {
        if ($('#checkOption' + i).is(':checked')) {
          data.description = $('#checkOption' + i).parent('label').text() + "\n\n" + data.description;
        }
      }

      var validateEmail = function(email) {
        var re = "^\\b[a-zA-Z0-9._%-\\+]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}\\b$";
        var regex = new RegExp(re);
        return regex.test(email);
      };

      if (data.country.trim() === "") {
        $('.form-group-country input', '.bob-frame').addClass('is-invalid');
        notValid = true;
      } else {
        $('.form-group-country input', '.bob-frame').removeClass('is-invalid');
        data.description = label.country + ": " + data.country + "\n\n" + data.description;
      }
      if (data.position.trim() === "") {
        $('.form-group-position input', '.bob-frame').addClass('is-invalid');
        notValid = true;
      } else {
        $('.form-group-position input', '.bob-frame').removeClass('is-invalid');
        data.description = label.position + ": " + data.position + "\n\n" + data.description;
      }
      if (data.organisation.trim() === "") {
        $('.form-group-organisation input', '.bob-frame').addClass('is-invalid');
        notValid = true;
      } else {
        $('.form-group-organisation input', '.bob-frame').removeClass('is-invalid');
        data.description = label.organisation + ": " + data.organisation + "\n\n" + data.description;
      }
      if (data.phone.trim() === "") {
        // $('.form-group-phone', '.bob-frame').addClass('is-invalid');
        // notValid = true;
      } else {
        $('.form-group-phone input', '.bob-frame').removeClass('is-invalid');
        data.description = label.phone + ": " + data.phone + "\n\n" + data.description;
      }
      if (data.email.trim() === "" || validateEmail(data.email.trim()) === false) {
        $('.form-group-email input', '.bob-frame').addClass('is-invalid');
        notValid = true;
      } else {
        $('.form-group-email input', '.bob-frame').removeClass('is-invalid');
        data.description = label.email + ": " + data.email + "\n\n" + data.description;
      }
      if (data.name.trim() === "") {
        $('.form-group-name input', '.bob-frame').addClass('is-invalid');
        notValid = true;
      } else {
        $('.form-group-name input', '.bob-frame').removeClass('is-invalid');
        data.description = label.name + ": " + data.name + "\n\n" + data.description;
      }

      if (notValid) {
        // enable button again
        $('#submit', '.bob-frame').prop('disabled', false);
        bobMsgWarning();
        return;
      }

      $('document').spinner('show');

      $.ajax({
        url: "/zen",
        type: "POST",
        data: data,
        cache: false,
        success: function (response) {
          bobMsgSuccess();
          // console.log(response);
          // google tag event
          window.dataLayer.push({"event": "conversionAccess"});
          // enable button again
          $('#submit', '.bob-frame').prop('disabled', false);
          $('document').spinner('hide');

        },
        error: function (xhr, b, x) {
          bobMsgError();
          // console.log(xhr, b, x);
          // enable button again
          $('#submit', '.bob-frame').prop('disabled', false);
          $('document').spinner('hide');
        }
      });

    };

    $('.bob-frame').on('hide.bobframe', function() {
      // if bobframe is closed, clear up some things
      // 1. remaining msg boxes
      if (msg === 'error' || msg === 'warning') {
        bobMsgHide();
      }
      // 2. remove red from inputs
      // $('.form-group input').removeClass('is-invalid');
    });

    $(document).off('.accessform');

    $(document).on('submit.accessform', '.bob-frame form.form-access', function (e) {
      sendData(e);
    });

    $(document).on('click.accessform', '.bob-msg', function () {
      bobMsgHide();
    });

    $(document).on('click.accessform', '.bob-frame .modal-close', function () {
      bobMsgHide();
      var event = $.Event('dohide.bobframe');
      $('.bob-frame').trigger(event);
    });

    return this;

  };

}(jQuery));
