(function ($) {

    $.fn.glyphClone = function (e) {

        var element = this;
        var clses = $(this).attr('class').split(' ');

        clses.forEach(function (cls) {
            if (cls.substr(0, 11) === 'glyphicons-') {
                if ($('#' + cls).length > 0) {
                    $(element).html($('#' + cls).clone().removeAttr('id').addClass(cls.substr(11)));
                }
            }
        });
    };

}(jQuery));